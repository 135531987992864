.App {
  background-color: black;
  height: 100vh; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  max-width: 100%; /* Ensure the image does not exceed its container's width */
  height: auto; /* Maintain the image's aspect ratio */
  max-height: 80vh; /* Limit the maximum height of the image */
}